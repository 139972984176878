import * as jose from "jose";

// create a jwt token
export const getJwtToken = async (props) => {
  try {
    const secret = process.env.REACT_APP_SECRET;
    const payload = {
      name: props.displayName,
      email: props.mail,
      azureID: props.id,
      accessLevel: 1,
      tokenCreation: Math.floor(Date.now() / 1000)
    };
    const jwt = await new jose.SignJWT(payload)
      .setProtectedHeader({ alg: "HS512" })
      .sign(new TextEncoder().encode(secret));
    return jwt;
  } catch (error) {
    throw error;
  }
};

// check if the jwt token is valid using the signature and retrieve the payload
export const decodeJwtToken = async (jwtToken) => {
  try {
    const secret = new TextEncoder().encode(process.env.REACT_APP_SECRET);
    const { payload } = await jose.jwtVerify(jwtToken, secret, {
      alg: "HS512",
    });
    return payload;
  } catch (error) {
    console.log(" Error decoding the token", error);
    throw error;
  }
};
