import React, { useState, useEffect } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "./AuthConfig";
import { TextField, Button, Typography, Box } from "@mui/material";
import { getJwtToken, decodeJwtToken } from "./JwtHandler.js";
import "./ProfileData.css";

export const ProfileData = (props) => {
  const [jwtToken, setJwtToken] = useState("");
  const [tokenExpirationTime, setTokenExpirationTime] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  // set expiration to one week from the current time
  const expiresInHours = parseInt(process.env.REACT_APP_JWT_EXPIRES_IN, 10);
  const expiresInSeconds = expiresInHours * 3600;
  useEffect(() => {
    const loadToken = async () => {
      const storedToken = localStorage.getItem("jwtToken");
      if (storedToken) {
        const payload = await decodeJwtToken(storedToken);
        const tokenCreation = payload.tokenCreation;
        const currentTime = Math.floor(Date.now() / 1000);

        // check if token is valid using tokenExpirationTime
        if (tokenCreation + expiresInSeconds > currentTime) {
          setJwtToken(storedToken);
          setIsButtonEnabled(false);
          setTokenExpirationTime(tokenCreation + expiresInSeconds);
        } else {
          // remove token from localStorage
          localStorage.removeItem("jwtToken");
          console.log("Existing token expired");
        }
      } else {
        console.log("No stored token");
      }
    };
    loadToken();
  }, []);

  const fetchJwtToken = async (token, username) => {
    try {
      const jwtToken = await getJwtToken(token, username);
      const payload = await decodeJwtToken(jwtToken);
      const tokenCreation = payload.tokenCreation;
      setJwtToken(jwtToken);
      setIsButtonEnabled(false);
      setTokenExpirationTime(tokenCreation + expiresInSeconds);
      localStorage.setItem("jwtToken", jwtToken);
    } catch (error) {
      localStorage.removeItem("jwtToken");
      setJwtToken("");
      setIsButtonEnabled(true);
      setTokenExpirationTime(null);
      console.error("Error fetching the token", error);
    }
  };

  const timeRemaining = (expirationTime) => {
    if (!expirationTime) return "";
    const expirationDate = new Date(expirationTime * 1000);
    return expirationDate.toLocaleString();
  };

  const handleLogin = () => {
    if (isAuthenticated) {
      // Logout
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
      localStorage.removeItem("jwtToken");
    } else {
      // Login
      instance.loginRedirect(loginRequest).catch((e) => {
        console.error(e);
      });
    }
  };
  const copyToClipboard = async () => {
    await navigator.clipboard
      .writeText(jwtToken)
      .then(() => {
        document.getElementById("copied").style.display = "inline";

        setTimeout(function () {
          document.getElementById("copied").style.display = "none";
        }, 1000);
      })
      .catch((err) => {
        console.error("Could not copy text:", err);
      });
  };

  return (
    <form>
      <TextField
        type="email"
        variant="outlined"
        color="primary"
        label="Email"
        value={props.graphData.userPrincipalName}
        fullWidth
        sx={{ mb: 4 }}
        readOnly
      />

      {isButtonEnabled && (
        <Button
          variant="outlined"
          onClick={() => fetchJwtToken(props.graphData)}
          color="primary"
          className="button-style"
          sx={{ mr: 1.5 }}
        >
          Generate Token
        </Button>
      )}

      {!isButtonEnabled && (
        <>
          <TextField
            multiline
            rows={6}
            variant="outlined"
            color="primary"
            label="Token"
            value={jwtToken}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            readOnly
            sx={{ mb: 2 }}
          />
          <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
            Token expires on: {timeRemaining(tokenExpirationTime)}
          </Typography>
          <Button
            variant="outlined"
            onClick={copyToClipboard}
            color="primary"
            className="button-style"
            sx={{ mr: 1 }} // Margin-left for spacing
          >
            Copy Token
          </Button>
        </>
      )}
      <Button
        variant="outlined"
        onClick={handleLogin}
        color="primary"
        className="button-style"
      >
        Sign Out
      </Button>
      <Box sx={{ mt: 2 }}>
        <Typography
          id="copied"
          color="textSecondary"
          variant="body3"
          display="none"
        >
          Copied token to clipboard!
        </Typography>
      </Box>
    </form>
  );
};
