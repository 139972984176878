/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken, endPoint) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(endPoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

// fetch the profile photo of the user
export async function callMsGraphPhoto(accessToken, endpoint) {
  const response = await fetch(endpoint, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error("Error fetching photo from Graph API");
  }
  // Return the photo as a Blob
  return response.blob();
}
