import React from "react";

import { BrowserRouter as Router } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./login/AuthConfig";
import { PublicClientApplication } from "@azure/msal-browser";

const msalInstance = new PublicClientApplication(msalConfig);

function Auth({ children }) {
  return (
    <Router>
      <React.StrictMode>
        <MsalProvider instance={msalInstance}>{children}</MsalProvider>
      </React.StrictMode>
    </Router>
  );
}

export default Auth;
