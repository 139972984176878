import React, { useState, useEffect } from "react";
import { loginRequest, graphConfig } from "./AuthConfig";
import { callMsGraph, callMsGraphPhoto } from "./Graph";
import { useMsal } from "@azure/msal-react";
import { ProfileData } from "./ProfileData";
import {
  Box,
  Typography,
  useTheme,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import "./ProfileData.css";

export const ProfileContent = () => {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [token, setToken] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });

        // Get user data
        const graphResponse = await callMsGraph(
          response.accessToken,
          graphConfig.graphMeEndpoint
        );
        setGraphData(graphResponse);

        // get user profile picture
        const photoResponse = await callMsGraphPhoto(
          response.accessToken,
          graphConfig.graphPhotoEndpoint
        );

        // Create URL from blob response object
        const photoURL = URL.createObjectURL(photoResponse);
        setPhotoURL(photoURL);
        setToken(response.accessToken);
      } catch (error) {
        console.error("Error fetching data from Graph API:", error);
      }
    };

    fetchData();
  }, [instance, accounts]);

  const theme = useTheme(); // Get the current theme
  const isDarkMode = theme.palette.mode === "dark";
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        textAlign: "left",
        padding: 3,
        backgroundColor: isDarkMode ? "#333" : "white",
        border: "1px solid grey",
        borderRadius: "10px",
        }}
    >
      <Typography
        variant="h5"
        component="h2"
        sx={{
          marginBottom: 2,
          color: isDarkMode ? "#b6e0f3" : "#0056a9", // Adjust color based on dark mode
          display: "flex",
          alignItems: "center",
          gap: 1, // Adds a small space between "Welcome" and the name
          flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        <span>Welcome </span>
        <b>{accounts[0]?.name}</b> 
        {photoURL ? (
          !isSmallScreen && (
            <img
              src={photoURL}
              alt="Profile"
              style={{
                width: 100,
                height: 100,
                borderRadius: "50%",
                marginLeft: "auto",
              }} // Align image to the right
            />
          )
        ) : (
          <Box className="spinner">
            <CircularProgress size={20} />
          </Box>
        )}
      </Typography>
      {graphData ? (
        <ProfileData graphData={graphData} token={token} />
      ) : (
        <Box className="spinner">
          <CircularProgress size={30} />
        </Box>
      )}
    </Box>
  );
};
